export default [
  {
    path: '/admin/account',
    component: () => import('../Layout/index'),
    meta: {
      title: 'Paciente',
      icon: 'icon-1',
      noCache: true,
      roles: [
        'ADMIN',
        'FD',
        'OPTO',
        'MD',
        'TS',
      ],
    },
    hidden: true,
    children: [
      {
        path: '',
        name: 'account',
        component: () => import('../views/Account'),
        meta: {
          title: 'Cuenta',
          roles: [
            'ADMIN',
            'FD',
            'OPTO',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'password',
        name: 'change password',
        component: () => import('../views/Password'),
        meta: {
          title: 'Cambiar Contraseña',
          roles: [
            'ADMIN',
            'FD',
            'OPTO',
            'MD',
            'TS',
          ],
        },
      },
    ],
  },
  {
    path: '/admin/patient',
    name: 'patient',
    component: () => import('../Layout/index'),
    meta: {
      title: 'Inicio',
      icon: 'icon-1',
      noCache: true,
      roles: [
        'ADMIN',
        'FD',
        'OPTO',
        'MD',
        'TS',
      ],
    },
    redirect: '/admin/patient/list',
    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('../views/home/medicalView'),
        meta: {
          title: 'Lista',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'OPTO',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('../views/patient/Register'),
        meta: {
          title: 'Registro',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'TS',
          ],
        },
      },
      {
        path: 'full-profile/:id',
        name: 'full-profile',
        component: () => import('../views/patient/FullProfile'),
        meta: {
          title: 'Perfil Completo',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'OPTO',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'general-profile/:id',
        name: 'general-profile',
        component: () => import('../views/profiles/General/index'),
        meta: {
          title: 'Perfil General',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'TS',
          ],
        },
      },
    ],
  },
  {
    path: '/admin/patient',
    name: 'searchPatient',
    component: () => import('../Layout/index'),
    meta: {
      title: 'Pacientes',
      icon: 'icon-3',
      noCache: true,
      roles: [
        'ADMIN',
        'FD',
        'OPTO',
        'MD',
        'TS',
      ],
    },
    redirect: '/admin/patient/list',
    children: [
      {
        path: 'list/patient',
        name: 'listPatient',
        component: () => import('../views/patient/List'),
        meta: {
          title: 'Lista2',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'OPTO',
            'MD',
            'TS',
          ],
        },
      },
    ],
  },
  {
    path: '/admin/appointments',
    component: () => import('../Layout/index'),
    meta: {
      title: 'Agendas',
      name: 'appointments',
      icon: 'icon-2',
      roles: [
        'ADMIN',
        'FD',
        'TS',
        'MD',
        'OPTO',
      ],
    },
    hiddenOnRole: [],
    children: [
      {
        path: '',
        name: 'appointments',
        component: () => import('../views/appointments/typeSwitchAppointment'),
        meta: {
          title: 'Lista',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'OPTO',
            'MD',
            'TS',
            'FD',
          ],
        },
      },
      {
        path: 'old',
        name: 'appointment',
        component: () => import('../views/appointments/index'),
        meta: {
          title: 'Lista',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'OPTO',
            'MD',
            'TS',
            'FD',
          ],
        },
      },
      {
        path: 'subsequent/:id',
        name: 'subsequent',
        hidden: true,
        component: () => import('../views/appointments/typesAppointments/Subsecuente'),
        meta: {
          title: 'Cita Subsecuente',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'cabinet/:id',
        name: 'cabinet',
        hidden: true,
        component: () => import('../views/appointments/typesAppointments/CabineteStudio'),
        meta: {
          title: 'Cita Subsecuente',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'operative/:id',
        name: 'operative',
        hidden: true,
        component: () => import('../views/appointments/typesAppointments/PostOperative'),
        meta: {
          title: 'Cita Subsecuente',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'emergencia/:id',
        name: 'emergencia',
        hidden: true,
        component: () => import('../views/appointments/typesAppointments/Emergencia'),
        meta: {
          title: 'Cita Subsecuente',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'MD',
            'TS',
          ],
        },
      },
      {
        path: 'social/:id',
        name: 'social',
        component: () => import('../views/appointments/Social'),
        hidden: true,
        meta: {
          title: 'Evaluación social',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'TS',
          ],
        },
      },
    ],
  },
  {
    path: '/admin/evaluations',
    name: 'evaluations',
    hidden: true,
    component: () => import('../Layout/index'),
    meta: {
      title: 'Evaluaciones',
      name: 'evaluations',
      roles: [
        'ADMIN',
        'OPTO',
        'MD',
        'TS',
        'FD',
      ],
    },
    children: [
      {
        path: 'card/:id',
        name: 'cardAppointment',
        component: () => import('../views/appointments/appointments/AppointmentsView'),
        hidden: true,
        meta: {
          title: 'cardAppointment',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'TS',
            'MD',
            'OPTO',
          ],
        },
      },
      {
        path: 'card/:id/:userId',
        name: 'cardAppointmentSocial',
        component: () => import('../views/appointments/appointments/AppointmentsView'),
        hidden: true,
        meta: {
          title: 'cardAppointment',
          noCache: true,
          roles: [
            'ADMIN',
            'FD',
            'TS',
            'MD',
            'OPTO',
          ],
        },
      },
      {
        path: 'ofta/:id/:idcita',
        name: 'OPHTA',
        component: () => import('../views/evaluations/Ofta/index'),
        meta: {
          title: 'Evaluación oftalmológica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'MD',
            'FD',
          ],
        },
      },
      {
        path: 'ofta/:id',
        name: 'OPHT',
        component: () => import('../views/evaluations/Ofta/index'),
        meta: {
          title: 'Evaluación oftalmológica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'MD',
            'FD',
          ],
        },
      },
      {
        path: 'oftahistory/:id/:idappointment',
        name: 'oftahistory',
        component: () => import('../views/evaluations/OftaHistory'),
        meta: {
          title: 'Evaluación historica oftalmológica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'MD',
            'OPTO',
            'TS',
            'FD',
          ],
        },
      },
      {
        path: 'oftahistoryNuevo/:id/:idappointment',
        name: 'oftahistoryNuevo',
        component: () => import('../components/PdfNuevoHistorial/oftahistory'),
        meta: {
          title: 'Evaluación historica oftalmológica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'MD',
            'OPTO',
            'TS',
            'FD',
          ],
        },
      },
      {
        path: 'SocialHistory/:id/:idappointment',
        name: 'SocialHistory',
        component: () => import('../views/evaluations/SocialHistory'),
        meta: {
          title: 'Evaluación historica oftalmológica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'MD',
            'OPTO',
            'TS',
            'FD',
          ],
        },
      },
      {
        path: 'opto/:id/',
        name: 'opto',
        component: () => import('../views/evaluations/Opto/index'),
        meta: {
          title: 'Evaluación optométrica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'OPTO',
            'MD',
            'FD',
          ],
        },
      },
      {
        path: 'opto/:id/:appointmentId',
        name: 'opto',
        component: () => import('../views/evaluations/Opto/index'),
        meta: {
          title: 'Evaluación optométrica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'OPTO',
            'MD',
            'FD',
          ],
        },
      },
      {
        path: 'opto/:id/:appointmentId/:evaluationId',
        name: 'opto',
        component: () => import('../views/evaluations/Opto/index'),
        meta: {
          title: 'Evaluación optométrica',
          icon: 'icon',
          noCache: true,
          roles: [
            'ADMIN',
            'OPTO',
            'MD',
            'FD',
          ],
        },
      },
      {
        path: 'socialeva/:id',
        name: 'social evaluation',
        component: () => import('../views/evaluations/SocioEconomic/index'),
        // component: () => import('../views/evaluations/SocioEconomic/newFeature'),
        meta: {
          title: 'Evaluación social',
          icon: 'icon',
          noCache: true,
          roles: ['ADMIN', 'TS'],
        },
      },
      {
        path: 'socialeva/:id/:appointmentId',
        name: 'social evaluation',
        component: () => import('../views/evaluations/SocioEconomic/index'),
        meta: {
          title: 'Evaluación social 2',
          icon: 'icon',
          noCache: true,
          roles: ['ADMIN', 'TS'],
        },
      },
    ],
  },
  {
    path: '/admin/comunidad',
    component: () => import('../Layout/index'),
    meta: {
      title: 'Comunidad',
      name: 'comunidad',
      icon: 'icon-4',
      roles: [
        'ADMIN',
        'TS',
        'FD',
      ],
    },
    hiddenOnRole: [],
    children: [
      {
        path: '',
        name: 'comunindades',
        component: () => import('../views/comunidad/index'),
      },
      {
        path: '/consultoriomovil',
        name: 'consultoriomovil',
        component: () => import('../views/comunidad/consultorioMovil/crearProyectoMovil'),
      },
      {
        path: '/jornadaClinica',
        name: 'jornadaClinica',
        component: () => import('../views/comunidad/jornadaClinica/crearProyecto'),
      },
      {
        path: '/consultoriomovil/:proyectoId',
        name: 'consultorioMovilRegistro',
        component: () => import('../views/comunidad/consultorioMovil/agregarPacientes'),
      },
      {
        path: '/jornadaclinica/:proyectoId',
        name: 'jornadaClinicaRegistro',
        component: () => import('../views/comunidad/jornadaClinica/agregarPacientes'),
      },
      {
        path: '/pacientes/:proyectoId/',
        name: 'proyectoPacientes',
        component: () => import('../views/comunidad/informacionProyecto'),
      },
    ],
  },
];
